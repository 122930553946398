import { Grid, Typography } from "@mui/material";
import styles from "../index.module.css";

const Contact = () => {
  return (
    <>
      <Grid item sm={12} className={styles.textGrid}>
        <Typography variant="h3" className={styles.headers}>
          How to contact us
        </Typography>
        <Typography variant="body" component="p">
          To ask any questions or book a stay with us either:
        </Typography>
        <Typography variant="body" component="p">
          Find us on:
        </Typography>
        <img className={styles.icons} src="/facebook.png" />
        <Typography variant="body" component="p">
          Dogs play and stay
        </Typography>
        <img className={styles.icons} src="/phone.png" />
        <Typography variant="body" component="p">
          07703511116
        </Typography>
      </Grid>
    </>
  );
};

export default Contact;
