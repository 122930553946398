import { Grid, Typography } from "@mui/material";
import styles from "../index.module.css";

const FancyStay = () => {
  return (
    <>
      <Grid item sm={5}>
        <img class={styles.img} src={"/stay.jpg"} />
      </Grid>
      <Grid item sm={7} className={styles.textGrid}>
        <Typography variant="h3" className={styles.headers}>
          Fancy a stay
        </Typography>
        <Typography variant="body" className={styles.pricingText}>
          Home Boarding from £25 plus vat per night
        </Typography>
        <Typography variant="body" className={styles.pricingText}>
          Day Care from £20 plus vat a day (8am – 6pm)
        </Typography>
        <Typography variant="body" className={styles.pricingText}>
          I am always happy to discuss your individual requirements
        </Typography>
        <Typography variant="body" className={styles.pricingText}>
          All dogs must be fully vaccinated, flea treated and wormed to come for
          day care or home boarding. Vets details will need to be provided on
          the first day and if your vet is located too far away you must be
          happy for me to take your dog to my vet if needed.
        </Typography>
        <Typography variant="body" className={styles.pricingText}>
          A 20% non refundable deposit must be made in order to secure your
          booking
        </Typography>
      </Grid>
    </>
  );
};

export default FancyStay;
