import { Grid } from "@mui/material";

const BreakLine = () => {
  return (
    <Grid item sm={12}>
      <hr />
    </Grid>
  );
};

export default BreakLine;
