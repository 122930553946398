import styles from "../index.module.css";
import { Container, Grid, Rating, Typography } from "@mui/material";
import AboutUs from "./AboutUs";
import AboutMe from "./AboutMe";
import BreakLine from "./BreakLine";
import FancyStay from "./FancyAStay";
import Contact from "./Contact";

export default function Home() {
  return (
    <>
      <div className={styles.headerImage}>
        <img src="./logo.png" className={styles.logo} />
      </div>
      <Container maxWidth="lg" className={styles.container}>
        <div className={styles.awards}>
          <div>
            <Typography variant="body" className={styles.awardText}>
              Licensed by Cornwall Council No. LI19_003992
            </Typography>
          </div>
          <div>
            <Typography variant="body" className={styles.awardText}>
              Awarded <Rating name="read-only" value={5} readOnly />
            </Typography>
          </div>
          <div>
            <Typography variant="body" className={styles.awardText}>
              Insured by Pet Plan
            </Typography>
          </div>
        </div>
        <hr />

        <Grid container spacing={2}>
          <AboutUs />
          <BreakLine />
          <AboutMe />
          <BreakLine />
          <FancyStay />
          <BreakLine />
          <Contact />
          <BreakLine />
          <Grid item xs={12} className={styles.textGrid}>
            <Typography variant="h4" component="p" className={styles.headers}>
              Dogs Play and Stay 2023
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
