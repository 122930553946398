import { Grid, Typography } from "@mui/material";
import styles from "../index.module.css";

const AboutMe = () => {
  return (
    <>
      <Grid item sm={7} className={styles.textGrid}>
        <Typography variant="h3" className={styles.headers}>
          About me
        </Typography>
        <Typography variant="body" component="p">
          My name is Tracey and I have had dogs all my life. At present I have
          two springer spaniels and two working collies. I live on a farm with
          cattle, sheep and a horse so I definitely say I have a passion for
          animals. My five animal loving children will always get stuck in.
          Whether it is for a short or long stay I treat every dog as if they
          were my own with my main aim to give the dogs as a much fun and
          exercise as they could wish for as well as lots of fuss and cuddles.
        </Typography>
      </Grid>
      <Grid item sm={5}>
        <img class={styles.img} src="/me.jpg" />
      </Grid>
    </>
  );
};

export default AboutMe;
