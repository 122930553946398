import { Grid, Typography } from "@mui/material";
import styles from "../index.module.css";

const AboutUs = () => {
  return (
    <>
      <Grid item sm={5}>
        <img class={styles.img} src="about.jpg" />
      </Grid>
      <Grid item sm={7} className={styles.textGrid}>
        <div>
          <Typography variant="h3" component="h3" className={styles.headers}>
            About us
          </Typography>
          <Typography variant="body" component="p">
            Based on our farm just outside of Redruth Dogs Play and Stay is a
            home from home environment for your dogs to play, have fun and make
            new doggie and human friends. With a large garden, secure yard and a
            two acre fully fenced field exclusively for the dogs to run, play
            and chase scents. Indoors they have access to the ground floor of my
            house and can sleep where they find it most comfortable.
          </Typography>
        </div>
      </Grid>
    </>
  );
};

export default AboutUs;
